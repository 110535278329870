import React from 'react'
import PropTypes from 'prop-types'
import style from './style'

const P = ({ value }) => (
  <div style={style.p}>
    {value}
  </div>
)

P.propTypes = {
  value: PropTypes.string
}

export default P
