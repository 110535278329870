import { validatePassword } from './passwordPolicy'

export const password = (value) => {
  const validatePasswordResult = validatePassword(value)
  if (validatePasswordResult instanceof Error) {
    return validatePasswordResult.message
  }
}

export const passwordConfirmation = (value, form) => {
  const confirmationPassword = form.password
  if (confirmationPassword !== value) {
    return 'Passwords do not match'
  }
}

export const required = (value) => {
  return !value ? 'Field is required' : undefined
}
