import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Typography }from '@material-ui/core'
import ResetPasswordForm from './ResetPasswordFrom'
import assets from '../../assets'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const FormContainer = styled.div`
  position: relative;
  min-width: 300px;
`

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0 0 0;
  > img {
    width: 250px;
  }
`

const SuccessContainer = styled.div`
  width: 100%;
  max-width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center;
  justify-content: center;
  padding: 20px 0 0 0;
  > img {
    width: 80px;
    margin-bottom: 20px;
  }
`

const ResetPassword = () => {
  const [submitted, setSubmitted] = useState(false)
 
  const onSubmissionComplete = () => {
    console.log('submitted')
    setSubmitted(true)
  }

  return (
    <Container>
      <FormContainer>
        <LogoContainer>
          <img src={assets.logo} alt={'Logo'} />
        </LogoContainer>
        {!submitted
        ? <ResetPasswordForm onSubmissionComplete={onSubmissionComplete} />
        : <SuccessContainer>
            <img src={assets.checkcircle} alt={'Circle'}/>
            <Typography variant="h6" component="h2" gutterBottom>
              Password Reset
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              If you have reset a password for another user, please make sure you notify them directly.
            </Typography>
          </SuccessContainer>
        }
      </FormContainer>
    </Container>
  )
}


ResetPassword.propTypes = {
  onSubmissionComplete: PropTypes.func
}

export default ResetPassword