export default {
  primary: '#ED3333',
  accent: '#000',
  offsetAccent: '#fff',
  error: '#ff0000',
  background: '#fff',
  border: '#e9e9e9',

  // Shades
  lightGrey: '#F4F4F4',

  // Text
  text: 'rgba(0,0,0,0.87)'
}
