import colors from '../../config/colors'

export default {
  p: {
    color: colors.text,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.5
  }
}
