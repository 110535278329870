import React from 'react'
import { Input, InputLabel, FormHelperText } from '@material-ui/core'
import style from './style'
import PropTypes from 'prop-types'

const InputField = ({
  label,
  onChange,
  onBlur,
  passedProps,
  meta: { error, touched }
}) => (
  <>
    <InputLabel htmlFor={label} shrink={true}>{label}</InputLabel>
    <Input
      id={label}
      name={label}
      label={label}
      error={!!(touched && error)}
      onChange={onChange}
      onBlur={onBlur}
      {...passedProps}

      floatingLabelFocusStyle={style.floatingLabelFocusStyle}
      underlineFocusStyle={style.underlineFocusStyle}
      errorStyle={style.errorStyle}
    />
    <FormHelperText error={!!(touched && error)} key='helper-text'>
      {touched && error && error}
    </FormHelperText>
  </>
)

InputField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  passedProps: PropTypes.object,
  meta: PropTypes.object
}

export default InputField