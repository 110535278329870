import { connect } from 'react-redux'
import ResetPasswordForm from './ResetPasswordForm'
import digitalStoreSdk from '../../../digitalStoreSdk'
import * as resetPasswordActions from '../../../actions/resetPasswordActions'

const mapStateToProps = state => ({ state })
const mapDispatchToProps = dispatch => ({
  dispatch,
  setError: (message) => {
    dispatch(resetPasswordActions.setErrorMessage(message))
  },
});

const mergeProps = ({ state }, { dispatch, setError }, ownProps) => {
  const { onSubmissionComplete } = ownProps

  const onSubmit = ({ password }) => {
    const resetToken = window.location.href.split('reset_token=')[1]
    return digitalStoreSdk.auth.changePassword({ resetToken, password })
    .then(() => {
      onSubmissionComplete()
    })
    .catch((err) => {
      setError(err.toString())
    })
  }
  return {
    ...ownProps,
    errorMessage: state.resetPassword.errorMessage,
    onSubmit
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ResetPasswordForm)
