import colors from '../../config/colors'

export default {
  generalError: {
    height: 20,
    textAlign: 'center',
    textTransform: 'capitalize',
    color: colors.error
  },
  formBody: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25
  }
}
