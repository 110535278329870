// Connects a basic field component with redux-forms field
// giving that field all passed props plus onChange, onBlur, value etc...
// http://redux-form.com/6.0.0-alpha.4/docs/faq/CustomComponent.md/

import React from 'react'
import { Field } from 'redux-form'

const FieldWrapper = WrappedField => props => {
  return <WrappedField {...props} {...props.input} />
}

export default (WrappedField) => {
  const MyField = FieldWrapper(WrappedField)
  return ({ validate, ...props }) => (
    <Field
      {...props}
      validate={validate}
      passedProps={props}
      component={MyField}
    />
  )
}
