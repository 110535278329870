import React, { Component } from 'react'
import qs from 'qs'
import _ from 'lodash'
import { Button } from '@material-ui/core'

import assets from '../../assets'
import P from '../../components/P'
import sdk from '../../digitalStoreSdk'

import style from './style'

const invalidTokenError = new Error('Invalid token.')

class FlagInappropriate extends Component {
  state = {
    status: 'LOADING'
  }

  getQueryParams = () => {
    const queryString = _.get(this.props, 'location.search', '').slice(1)
    const { customerId, messageId, token } = qs.parse(queryString)
    return { customerId, messageId, token }
  }

  validateToken = () => {
    const { customerId, token } = this.getQueryParams()

    return sdk.customers
      .validateCustomerToken({ token })
      .then(tokenResult => {
        if (
          tokenResult.customerId === customerId &&
          tokenResult.tokenType === 'flag-inappropriate'
        ) {
          return tokenResult
        } else {
          throw new Error()
        }
      })
      .catch(error => {
        console.error(error)
        throw invalidTokenError
      })
  }

  flagMessage = () => {
    const { messageId, customerId, token } = this.getQueryParams()
    
    this.setState({ status: 'LOADING' })
    return sdk.messages
      .flagMessage({
        messageId,
        customerId,
        token
      })
      .then(() => {
        this.setState({ status: 'SUCCESS' })
      })
      .catch(() => {
        this.setState({ status: 'ERROR' })
      })
  }

  componentDidMount () {
    // Check token is valid
    this
      .validateToken()
      .then(() => {
        this.setState({ status: 'TOKEN_VALID' })
      })
      .catch(error => {
        if (error === invalidTokenError) {
          this.setState({ status: 'INVALID_TOKEN' })
        } else {
          this.setState({ status: 'ERROR' })
        }
      })
  }

  render () {
    const { status } = this.state
    return (
      <div style={style.container}>
        <div style={style.logoContainer}>
          <img src={assets.logo} style={style.logo} />
        </div>
        <div style={style.messageContainer}>
          {status === 'TOKEN_VALID' ? (
            <div style={style.buttonContainer}>
              <P value={`Please confirm that you would like to flag this message as inappropriate`} />
              <Button style={style.button} onClick={this.flagMessage}>Confirm</Button>
            </div>
          ) : (
            <P value={`FLAG_${status}`} />
          )}
        </div>
      </div>
    )
  }
}

export default FlagInappropriate
