import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'

import ResetPassword from './containers/ResetPassword'
import Unsubscribe from './containers/Unsubscribe'
import FlagInappropriate from './containers/FlagInappropriate'

import createStore from './store/createStore'

const store = createStore()

const theme = {
  error: "red"
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route key='reset-password' path='/reset-password' component={ResetPassword} />
          <Route key='unsubscribe' path='/unsubscribe' component={Unsubscribe} />
          <Route key='flag-message' path='/flag-message' component={FlagInappropriate} />
        </Switch>
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);