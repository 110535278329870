import * as resetPasswordConstants from '../constants/resetPasswordConstants'

const initialState = {} 
export const resetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
      case resetPasswordConstants.SET_ERROR_MESSAGE:
        return {
          ...state,
          errorMessage: action.payload,
        };
      default:
        return state;
    }
  };
