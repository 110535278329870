export default {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 800,
    margin: 'auto',
    marginTop: 200,
  },
  logoContainer: {
    width: 250
  },
  logo: {
    width: '100%'
  },
  messageContainer: {
    marginTop: 20
  }
}
